import React from "react"
import { Carousel, Modal } from "react-bootstrap"
import Img from "gatsby-image"
import './Carousel.scss';


const MyCarousel = ({items, className, withModal}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [modalItem, setModalItem] = React.useState(null);

  const handleShowModal = (item) => {
    setModalItem(item);
    setShowModal(true);
  };

  const handleHideModal = () => {
    setModalItem(null);
    setShowModal(false);
  };

  const CImg = ({fluid, text}) => (
    <Img
      className="w-100"
      style={{
        left: "50%", 
        transform: "translateX(-50%)"
      }}
      fluid={fluid}
      alt={text}
    />
  );
  return (
    <>
      <Carousel className={className} interval={null}>
        {items.map((item, i) => (
          <Carousel.Item key={i}>
            {withModal && (
              <a style={{cursor: "zoom-in"}} onClick={() => handleShowModal(item)}>
                <CImg fluid={item.fluid} text={item.text}/>
              </a>
            )}
            {!withModal && (
              <CImg fluid={item.fluid} text={item.text}/>
            )}
            {item.text && (
              <Carousel.Caption>
                <p>{item.text}</p>
              </Carousel.Caption>
            )}
          </Carousel.Item>
        ))}
      </Carousel>
      {withModal && (
        <Modal 
          className="carousel-modal"
          show={showModal}
          onHide={handleHideModal}
          dialogClassName="modal-100w"
          >
          <Modal.Header closeButton />
          <Modal.Body>
            <>
              {modalItem && (<img src={modalItem.original} alt={modalItem.text} />)}
            </>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

MyCarousel.defaultProps = {
  withModal: false,
}

export default MyCarousel;
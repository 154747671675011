import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { MDXRenderer } from "gatsby-plugin-mdx"
import SEO from 'components/seo'
import CategorieIcon from "../components/CategorieIcon"
import Carousel from "../components/Carousel"
const RealisationsTemplate = ({
  data, // this prop will be injected by the GraphQL query below.
}) => {
  const { mdx } = data // data.mdx holds your post data
  const { frontmatter, body, excerpt } = mdx;
  const images1 = [];
  if(frontmatter.images1){
    frontmatter.images1.map((image, i) => {
      if(image.image){
        images1.push({
          fluid: image.image.childImageSharp.fluid,
          text: image.text,
          original: image.image.childImageSharp.original.src,
        });
      }
      return null;
    });
  }
  const images2 = [];
  if(frontmatter.images2){
    frontmatter.images2.map((image, i) => {
    if(image.image){
      images2.push({
        fluid: image.image.childImageSharp.fluid,
        text: image.text,        
        original: image.image.childImageSharp.original.src,
      });
    }
    return null;
    });
  }
  return (
    <>
      <SEO title={frontmatter.title} description={excerpt}/>
      <Container fluid className="container">
        <Row className="justify-content-md-center section-section">
          <Col md="8">
            <h2>{frontmatter.title}</h2>
          </Col>
          <Col md="4">
            <CategorieIcon className="categorie-icon" categorie={frontmatter.categorie} full={true}/>
          </Col>
        </Row>
        <Row className="justify-content-md-center carousel-row">
          {images1.length !== 0 && images2.length !== 0 && (
            <>
            <Col md="6">
              <Carousel withModal items={images1} className="carousel1"/>
            </Col>
            <Col md="6">
              <Carousel withModal items={images2} className="carousel2"/>
            </Col>
            </>
          )}
          {images1.length !== 0 && images2.length === 0 && (
            <Col md="12">
              <Carousel withModal items={images1} className="carousel1"/>
            </Col>
          )}
        </Row>
        <Row className="justify-content-md-center section-section">
          <Col md="12">
            <MDXRenderer className="pages-content">
              {body}
            </MDXRenderer>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default RealisationsTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        categorie
        images1 {
          image {
            childImageSharp {
              fluid(quality: 100, maxHeight: 750) {
                ...GatsbyImageSharpFluid_tracedSVG
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
              original {
                src
              }
            }
          }
          text
        }
        images2 {
          image {
            childImageSharp {
              fluid(quality: 100, maxHeight: 750) {
                ...GatsbyImageSharpFluid_tracedSVG
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
              original {
                src
              }
            }
          } 
          text
        }
      }
    }
  }
`

// {
//   childImageSharp {
//     fluid(maxWidth: 800) {
//       ...GatsbyImageSharpFluid_tracedSVG
//     }
//   }
// }
// {
//   childImageSharp {
//     fluid(maxWidth: 800) {
//       ...GatsbyImageSharpFluid_tracedSVG
//     }
//   }
// }